/*
body {
  border: 5px solid red;
}
 */

/* Categories in menu desktop */
.menu-categories{
  @apply my-0 block mx-2 lg:mx-4;

  a{
    @apply text-xs lg:text-sm font-bold leading-5 text-left cursor-pointer text-neutral-700 font-archivo;
  }
}

/* menu team in header menu */
.team-menu-desktop {
  @apply block;
  menu {
    @apply flex flex-row justify-evenly mx-0 my-2;
    img {
      @apply inline transition-[0.3s] duration-[all] ease-[ease-in-out] hover:scale-[1.4];
    }
  }
}

.foo.bar.foo{
  display: none;
}